var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-screen min-contents flex-1 mb-10" }, [
    _c("div", { staticClass: "col-span-12 py-2 flex-1" }, [
      _c("div", { staticClass: "movie_top" }, [
        _c("div", { staticClass: "display-block inset-0" }, [
          _vm.consumer.main_image
            ? _c("img", {
                staticClass: "w-full object-cover object-center max-h-550",
                attrs: { src: _vm.consumer.main_image.url },
              })
            : _vm._e(),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "container mx-auto gap-6 mt-6 mb-6" }, [
      _c("div", { staticClass: "object-fill" }, [
        _vm.consumer.logo_image
          ? _c("img", {
              staticClass:
                "circle w-full object-cover object-center inline-block",
              attrs: { src: _vm.consumer.logo_image.url },
            })
          : _vm._e(),
        _c(
          "h1",
          { staticClass: "font-bold tracking-widest h-12 inline-block ml-10" },
          [_vm._v(_vm._s(_vm.$t(_vm.consumer.company_name)))]
        ),
      ]),
    ]),
    _c("div", { staticClass: "border-b border-gray-600 mb-4" }),
    _c(
      "div",
      { staticClass: "container mx-auto grid grid-cols-12 gap-6 mt-6" },
      [
        _c("label", { staticClass: "col-start2 col-span-3 py-2 text-right" }, [
          _vm._v(_vm._s(_vm.$t("commons.fields.company_website"))),
        ]),
        _c("div", { staticClass: "col-span-10 h-10 py-2" }, [
          _vm._v(_vm._s(_vm.$t(_vm.consumer.company_url))),
        ]),
        _c("label", { staticClass: "col-start-2 col-span-2 py-2 text-right" }, [
          _vm._v(_vm._s(_vm.$t("commons.fields.address"))),
        ]),
        _vm.consumer.hq_street &&
        _vm.consumer.hq_city &&
        _vm.consumer.hq_state &&
        _vm.consumer.hq_zip
          ? _c("span", { staticClass: "col-span-10 py-2" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.consumer.hq_street +
                      ", " +
                      _vm.consumer.hq_city +
                      ", " +
                      _vm.consumer.hq_state +
                      " " +
                      _vm.consumer.hq_zip
                  ) +
                  " "
              ),
            ])
          : _vm._e(),
        _c("label", { staticClass: "col-start-2 col-span-2 py-2 text-right" }, [
          _vm._v(_vm._s(_vm.$t("commons.fields.company_country"))),
        ]),
        _vm.consumer.hq_country
          ? _c("span", { staticClass: "col-span-10 py-2" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.textOfEnum(_vm.allCountries, _vm.consumer.hq_country)
                  ) +
                  " "
              ),
            ])
          : _vm._e(),
        _c("label", { staticClass: "col-start-2 col-span-2 py-2 text-right" }, [
          _vm._v(_vm._s(_vm.$t("commons.fields.company_type"))),
        ]),
        _c(
          "div",
          { staticClass: "col-span-10 h-10 py-2" },
          [
            _c("fl-show", {
              attrs: {
                value: _vm.consumer.company_type,
                options: _vm.companyTypeConsumer,
              },
            }),
          ],
          1
        ),
        _c("label", { staticClass: "col-start-2 col-span-2 py-2 text-right" }, [
          _vm._v(_vm._s(_vm.$t("commons.fields.business_type"))),
        ]),
        _c(
          "div",
          { staticClass: "col-span-10 py-2" },
          _vm._l(_vm.consumer.business_types, function (item, index) {
            return _c("fl-show", {
              key: index,
              staticClass: "mb-2",
              attrs: { value: item.business_type, options: _vm.businessTypes },
            })
          }),
          1
        ),
        _c("label", { staticClass: "col-start-2 col-span-2 py-2 text-right" }, [
          _vm._v(_vm._s(_vm.$t("commons.fields.distribution_area"))),
        ]),
        _c(
          "div",
          {
            staticClass:
              "field col-span-10 py-2 flex justify-start flex-wrap content-around",
          },
          [
            _vm._l(_vm.consumer.areas, function (area, key) {
              return [
                _c(
                  "div",
                  {
                    key: key,
                    staticClass:
                      "py-1 px-2 mr-2 mb-2 genre-tag-label text-sm text-white",
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.textOfEnum(_vm.allAreas, area.area)) +
                        " "
                    ),
                  ]
                ),
              ]
            }),
          ],
          2
        ),
        _c("FlShowGenre", {
          attrs: {
            fieldId: "mainGenres",
            isEdit: false,
            isMainGenre: true,
            areaClass: "col-start-2 col-span-12 ml-3",
            areaGrid: 12,
            labelGrid: 2,
            labelClass: "py-2",
            inputErrors: false,
          },
          model: {
            value: _vm.consumer.genres,
            callback: function ($$v) {
              _vm.$set(_vm.consumer, "genres", $$v)
            },
            expression: "consumer.genres",
          },
        }),
        _c("FlShowGenre", {
          attrs: {
            fieldId: "subGenres",
            isEdit: false,
            isMainGenre: false,
            areaClass: "col-start-2 col-span-12 ml-3",
            areaGrid: 12,
            labelGrid: 2,
            labelClass: "py-2",
            inputErrors: false,
          },
          model: {
            value: _vm.consumer.genres,
            callback: function ($$v) {
              _vm.$set(_vm.consumer, "genres", $$v)
            },
            expression: "consumer.genres",
          },
        }),
        _c("label", { staticClass: "col-start-1 col-span-3 py-2 text-right" }, [
          _vm._v(_vm._s(_vm.$t("commons.fields.annual_revenue"))),
        ]),
        _c("div", { staticClass: "col-span-10 h-10 py-2" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.consumer.annual_revenue_size &&
                  _vm.consumer.annual_revenue_currency
                  ? _vm.formatCurrency(
                      _vm.consumer.annual_revenue_size,
                      _vm.consumer.annual_revenue_currency
                    )
                  : ""
              ) +
              " "
          ),
        ]),
        _c("label", { staticClass: "col-start-1 col-span-3 py-2 text-right" }, [
          _vm._v(_vm._s(_vm.$t("commons.fields.number_of_users"))),
        ]),
        _c("div", { staticClass: "col-span-10 h-10 py-2" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.consumer.user_count
                  ? _vm.consumer.user_count.toLocaleString("en-US")
                  : 0
              ) +
              " "
          ),
        ]),
        _vm.loginUserRole !== "provider"
          ? [
              _c(
                "label",
                { staticClass: "col-start-1 col-span-3 py-2 text-right" },
                [_vm._v(_vm._s(_vm.$t("commons.fields.payment_period")))]
              ),
              _c(
                "div",
                { staticClass: "col-span-1 h-10 py-2" },
                [
                  _c("fl-show", {
                    attrs: {
                      value: _vm.consumer.payment_period,
                      options: _vm.paymentPeriod,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-span-8 h-10 py-2" },
                [_c("fl-show", { attrs: { value: _vm.paymentStartMonth } })],
                1
              ),
            ]
          : _vm._e(),
        _c("label", { staticClass: "col-start-2 col-span-2 py-2 text-right" }, [
          _vm._v(_vm._s(_vm.$t("pages.consumers.profile.about_us"))),
        ]),
        _c("span", { staticClass: "col-span-10 py-2 whitespace-pre-wrap" }, [
          _vm._v(_vm._s(_vm.consumer.translations[_vm.$i18n.locale].about)),
        ]),
        _c("label", { staticClass: "col-start-2 col-span-2 py-2 text-right" }, [
          _vm._v(_vm._s(_vm.$t("pages.consumers.profile.company_pr"))),
        ]),
        _c("span", { staticClass: "col-span-10 py-2 whitespace-pre-wrap" }, [
          _vm._v(_vm._s(_vm.consumer.translations[_vm.$i18n.locale].pr)),
        ]),
        _vm.loginUserRole !== "provider"
          ? [
              _c(
                "label",
                { staticClass: "col-start-1 col-span-3 py-2 text-right" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("pages.consumers.profile.delivery_information")
                      ) +
                      " "
                  ),
                ]
              ),
              _vm._l(_vm.consumer.delivery_files, function (item) {
                return _c(
                  "span",
                  { key: item, staticClass: "col-start-4 col-span-10 py-2" },
                  [
                    _vm._v(" " + _vm._s(item.file_name) + " "),
                    _c(
                      "button",
                      {
                        key: item,
                        staticClass:
                          "pl-2 pr-2 mr-2 bg-base-yellow font-bold active:bg-off-yellow-darker",
                        on: {
                          click: function ($event) {
                            return _vm.downloadFile(
                              item.file.url,
                              item.file.file_name
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("pages.commons.quotes.download")) +
                            " "
                        ),
                      ]
                    ),
                  ]
                )
              }),
            ]
          : _vm._e(),
        _vm.loginUserRole !== "consumer"
          ? [
              _c(
                "label",
                { staticClass: "col-start-2 col-span-2 py-2 text-right" },
                [
                  _vm._v(
                    _vm._s(_vm.$t("pages.consumers.preview.admin_comment"))
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "col-span-10 py-2 whitespace-pre-wrap" },
                [_vm._v(_vm._s(_vm.consumer.admin_comment))]
              ),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }